@import "styles";

.checkbox-field {
  display: inline-flex;

  input[type=checkbox] {
    min-height: 22px;
    min-width: 22px;
  }
}

.intl-tel-input {
  width: 100%;
}

.country-list {
  @extend %body3;
}