@import 'styles';

input[type='text'],
input[type='number'],
input[type='url'],
input[type='email'],
input[type='tel'],
select {
  @extend %body4;
  color: $text-color;
  width: 100%;
  height: 40px;
  border: 1px solid $editor-body-border-color;
  border-radius: $input-border-radius;
  outline: none;

  &.login {
    height: 60px;
    @extend %body1;
  }

  &.error {
    border: 1px solid $error-border-color;
  }
}

.floating-error-container {
  position: relative;

  .floating-error {
    transform: translateY(100%);
    background-color: $button-subtle-hover-delete-border-color;
    border: 1px solid $error-border-color;
    border-radius: 4px;
    bottom: 4px;
    color: $error-text-color;
    display: inline-block;
    font-size: 12px;
    left: 0;
    line-height: 1;
    padding: 1px 4px;
    position: absolute;
  }
}

.intl-tel-input {
  input[type='tel'] {
    height: 60px;
    @extend %body1;
    color: $text-color;
  }
}

textarea {
  @extend %body4;
  color: $text-color;
  width: 99%;
  height: 150px;
  border-radius: $input-border-radius;
  border: 1px solid $editor-body-border-color;
  outline: none;
}

button {
  outline: none;
}

input[type='text'],
input[type='number'],
input[type='url'],
input[type='email'],
select,
textarea {
  padding: 10px 10px 10px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

select {
  color: $button-text-color;
  -webkit-appearance: none;
  appearance: none;
  background: url(./images/drop-down.svg) no-repeat right #fff;
  background-position-x: 100%;
  color: $text-color;
}

.input-container {
  position: relative;

  input:invalid {
    padding-right: 32px;
    border: 1px solid $error-border-color;
  }

  input:valid {
    padding-right: 6px;
  }

  input:invalid + .error-tooltip-container {
    display: flex;
  }

  input:valid + .error-tooltip-container {
    display: none;
  }
}

.error-tooltip-container {
  bottom: 0;
  height: 24px;
  margin: auto;
  position: absolute;
  right: 6px;
  width: auto !important;
  top: 0;
  z-index: 3;
  align-items: center;
  line-height: 0;
  color: $error-danger-color;
}
