@import "./styles";

div.jsoneditor,
div.jsoneditor-menu {
  border-color: $complementary-color;
}
div.jsoneditor-menu {
  background-color: $complementary-color;
  margin-bottom: 8px;
}
div.jsoneditor-statusbar {
  background-color: $complementary-color;
  border-color: $complementary-color;
}
.jsoneditor-menu button,
.jsoneditor-menu button:hover,
.jsoneditor-menu > .jsoneditor-modes button.jsoneditor-modes,
.jsoneditor-menu > .jsoneditor-modes button.jsoneditor-modes:hover {
  background-color: $primary-color;
}
.jsoneditor-search button {
  background-color: #ffffff;
}
.jsoneditor-type-modes.jsoneditor-selected .jsoneditor-text{
  color: $primary-color;
}
.jsoneditor-statusbar .jsoneditor-curserinfo-label,
.jsoneditor-statusbar .jsoneditor-curserinfo-val {
  font-family: $font;
  color: $text-color;
}
.jsoneditor-contextmenu {
  z-index: 3;
}
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected,
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:hover,
.jsoneditor-contextmenu .jsoneditor-menu li button.jsoneditor-selected:focus {
  background-color: #ffffff;
}
.jsoneditor-navigation-bar {
  background-color: $complementary-color;
  font-family: $font;
  color: $text-color;
  border-bottom: none;
  margin-bottom: 8px;
}
.jsoneditor-menu, .jsoneditor-tree {
  background-color: #ffffff;
}
div.jsoneditor-tree {
  height: 97%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.jsoneditor-poweredBy, .jsoneditor-transform, .jsoneditor-sort {
  display:none;
}
.jsoneditor-results {
  @extend %body3;
  color: $text-color;
}
div.jsoneditor-search
{
  .jsoneditor-frame {
    input[type=text] {
      background-color: #ffffff;
      border: none;
      //padding: none;
      padding-left: 8px;
      padding-right: 8px;
      //box-sizing: none;
      width: 115px;
      height: 20px;
  
      &:focus {
        border: none;
      }
    }
  }
}