@import "styles";
@import "_inputs";

.onboarding-layout {

  .preview-setup-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;   
    iframe {
      margin: 32px;
      width: 100%;
      height: 100%;
      max-width: 760px;
      max-height: 415px;
      border: 3px solid #ffffff;
      border-radius: $border-radius;
    }
  }

  .preview-integration-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .preview-integration-container {
    img {
      position: absolute;
      transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
      visibility: hidden;
      opacity: 0;

      &.visible {
        transition: visibility 0.8s ease-in-out, opacity 0.8s ease-in-out;
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .preview-platform-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100px;
      height: 100px;
      padding: 16px;
    }
  }

  .preview-usage-container {
    width: 100%;
    margin: 16px 0px 16px 0px;
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: center;
    img {
      padding: 8px;
      max-width: 40%;
    }
  }

  .preview-message-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .preview-message {
      margin: 32px;
      max-width: 433px;
      border: 2px solid;
      border-radius: 16px;
      padding: 32px;
      h1 {
        @extend %preview-heading;
        margin-bottom: 16px;
        text-align: center;
      }
      p {
        @extend %preview-body;
        margin-bottom: 8px;
      }
      .preview-button {
        border-radius: $onboarding-button-border-radius;
        width: 100%;
        text-align: center;
        margin-top: 16px;
        @extend %preview-button;
        padding: 24px 0 24px 0;
        cursor: pointer;
      }
    }
  }

  .splitter {
    display: flex;

    .left {
      width: 50%;
    }

    .right {
      width: 50%;
      background: $tertiary-color;
    }
  }

  .logo {
    padding: 48px 0px 48px 0px;
  }

  .forms {
    padding: 48px;
  }
  
  .visuals {
    display: flex;
    min-height: 100vh;
  }

  .step-viewer {
    display: flex;
    margin-top: 104px;

    width: 100%;
    height: 5px;

    .step {
      width: 75px;
      height: 5px;
      margin-right: 5px;
      background-color: #ccc;

      &.active {
        background-color: $bottom-action-bar-button-primary-color;
      }
    }
  }

  .onboarding {
    margin-top: 32px;

    .onboarding-heading {
      @extend %onboarding-heading;
      color: $text-color;
    }
  
    .onboarding-subheading {
      @extend %onboarding-subheading;
      margin-top: 16px;
      color: $text-color;
    }

    .onboarding-body {
      margin-top: 48px;
    }

    .onboarding-forward-container {
      display: flex;
      margin-top: 48px;
    }
  
    .colors {
      position: relative;
      z-index: 1000;
      padding: 32px 0px 32px 0px;
      display: grid;
      grid-auto-columns: min-content;
      grid-gap: 32px;
      grid-template-columns: repeat(auto-fit, minmax(100px, 100px) );
  
      .item {    
        .color {
          @extend %body4;
          display: flex;
          align-items: center;
          justify-content: center;
          height:100px;
          width:100px;
          border-radius: 50px;
          box-shadow: 1px 1px 12px $editor-preview-bubble-shadow;
  
          &:hover {
            cursor: pointer;
          }
        }
        .title {
          margin-top:16px;
          @extend %body2;
          text-align: center;
        }
      }
    }
  
    .color-picker {
      .cover {
        position: absolute;
        left: -100vh;
        top: -100vh;
        bottom: -100px;
        right: -100px;
      }
  
      .popover {
        margin-top: 10px;
        position: absolute;
        z-index: 100000;
      }
    }
  
    .spaced {
      margin-top:16px;
    }
  
    .multiselect-list {
      .item {
        display: flex;
        @extend %body1;
        cursor: pointer;
        margin-top: 8px;
      }
  
      input[type=checkbox] {
        min-height: 22px;
        min-width: 22px;
      }

      select {
        width: 300px;
        height: 50px;
        padding: 8px;
        border-radius: $border-radius;
        @extend %body2;
      }
    }
  
    .onboarding-button {
      @extend %body1;
      padding: 16px 24px;
      border-radius: $onboarding-button-border-radius;
      background-color: $bottom-action-bar-button-primary-color;
      color: $bottom-action-bar-button-text-color;
      outline: none;
      border: none;

      &:hover {
        cursor: pointer;
      }
  
      .loading {
        width: 50px;
        padding: 2px 0px;
      }
    }

    .help {
      display: flex;
      flex-direction: column;

      .setup-help {
        display: inline-block;
        
        padding: 16px;
        margin-bottom: 16px;
        background-color: $help-background;
        border-radius: $border-radius;

        p {
          @extend %body2;
          margin-top: 16px;
        }

        h2 {
          @extend %heading1;
        }

        a {
          text-decoration: none;
        }
      }

      .setup-button {
        @extend %body3;
        border: 1px solid $primary-color;
        border-radius: $onboarding-button-border-radius;
        color: $primary-color;
        display: inline-flex;
        align-items: center;
        flex-shrink: 1;
        padding: 16px;
        margin-top: 16px;
        cursor: pointer;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }    
  }

  .slide-up-fade-in {
    animation: slide-up-fade-in ease 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 50%;
    animation-fill-mode: forwards; /*when the spec is finished*/
    -webkit-animation: slide-up-fade-in ease 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-transform-origin: 50% 50%;
    -webkit-animation-fill-mode:forwards; /*Chrome 16+, Safari 4+*/ 
    -moz-animation: slide-up-fade-in ease 1s;
    -moz-animation-iteration-count: 1;
    -moz-transform-origin: 50% 50%;
    -moz-animation-fill-mode: forwards; /*FF 5+*/
    -o-animation: slide-up-fade-in ease 1s;
    -o-animation-iteration-count: 1;
    -o-transform-origin: 50% 50%;
    -o-animation-fill-mode: forwards; /*Not implemented yet*/
    -ms-animation: slide-up-fade-in ease 1s;
    -ms-animation-iteration-count: 1;
    -ms-transform-origin: 50% 50%;
    -ms-animation-fill-mode: forwards; /*IE 10+*/
    opacity: 0;
    opacity: 1\9;
  }

  @keyframes slide-down-fade-out {
    0% {
      opacity: 1;
      transform: translate(40px,0px);
    }
    100% {
      opacity: 0;
      transform: translate(0px,0px);
    }
  }

  @keyframes slide-up-fade-in {
    0% {
      opacity: 0;
      transform: translate(0px,40px);
    }
    100% {
      opacity: 1;
      transform: translate(0px,0px);
    }
  }

  @-moz-keyframes slide-up-fade-in{
    0% {
      opacity: 0;
      -moz-transform: translate(0px,40px);
    }
    100% {
      opacity: 1;
      -moz-transform: translate(0px,0px);
    }
  }

  @-webkit-keyframes slide-up-fade-in {
    0% {
      opacity: 0;
      -webkit-transform: translate(0px,40px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translate(0px,0px);
    }
  }

  @-o-keyframes slide-up-fade-in {
    0% {
      opacity: 0;
      -o-transform: translate(0px,40px);
    }
    100% {
      opacity: 1;
      -o-transform: translate(0px,0px);
    }
  }

  @-ms-keyframes slide-up-fade-in {
    0% {
      opacity: 0;
      -ms-transform: translate(0px,40px);
    }
    100% {
      opacity: 1;
      -ms-transform: translate(0px,0px);
    }
  }
}

@media (max-width: 1000px) {
  body {
    .onboarding-layout {
      .splitter {
        .left {
          width: 100%;
        }
    
        .right {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  body {
    .onboarding-layout {
      .modal-overlay {
        .modal-overlay-container {
          width: 70%;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  body {
    .onboarding-layout {
      .onboarding {
        .onboarding-heading {
          font-size: 36px;
        }
      }

      .step-viewer {
        margin-top: 52px;
        .step {
          width: 40px;
          height: 5px;
        }
      }
    }

    .editor-card-container .card .body .field .input-field-title {
      font-size: 12px;
    }
  }
}