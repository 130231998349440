@import 'app';

.editor-card-container {
  .builder {
    min-height: 600px;
  }
}

.layers-wrapper {
  display: flex;
  flex: 1;
  background-color: white;
  border-right: 1px solid $border-color;
  max-width: 300px;
}

.layers {
  display: flex;

  &.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    align-self: flex-start;
    overflow-y: auto;
    width: 100%;

    .card {
      display: flex;
      align-self: stretch;
      flex: 1;
      background-color: white;
      flex-direction: column;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        padding: 12px 24px 0px 24px;
        height: 36px;
      }

      .layers-container {
        display: flex;
        align-self: stretch;
        padding: 0px 0px 16px 0px;
        max-height: calc(100vh - 195px);
        overflow: scroll;
      }
    }
  }
}

.live-preview-wrapper {
  display: flex;
  flex: 1;
  background-color: white;
  border-left: 1px solid $border-color;
}

.live-preview {
  &.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 70px;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    flex: 1;

    .live-preview-card {
      @import 'inputs';
      background-color: white;
      display: flex;
      flex-direction: column;

      .header {
        color: $heading-text-color;
        display: flex;
        padding: 12px 24px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        height: 36px;
      }

      .live-preview-container {
        display: flex;
        text-align: center;
        justify-content: center;

        .query-field {
          max-width: 413px;
          margin-bottom: 16px;
        }
        .live-preview-iframe-container {
          padding: 24px;
          border-radius: $border-radius;
          background-color: $border-color;
        }
        .live-preview-frame {
          width: 100%;
          border-radius: $border-radius;
          height: 600px;
          min-width: 414px;
        }
      }
    }
  }
}

.builder {
  .code-view {
    height: 625px;
    width: 100%;
  }

  .notice {
    border-radius: $border-radius;
    padding: 16px 16px 16px 16px;
    margin: 16px 0px 0px 16px;
    background-color: white;
    text-align: center;

    a {
      text-decoration: none;
    }
  }

  .component-builder {
    padding-bottom: 16px;

    .sub-component {
      margin-top: 8px;

      .details {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }

    .header {
      display: flex;
      justify-content: space-between;
      border-radius: 4px;
      border: 1px solid $border-color;

      .container {
        display: flex;
        align-items: center;
        padding-right: 8px;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      .title-container {
        display: flex;
        align-items: center;
        svg {
          margin-right: 8px;
        }
      }

      .component-title {
        color: $heading-text-color;
        min-width: 60px;
        min-height: 15px;
      }

      &.minimizable {
        cursor: pointer;
      }
      .minimize-icon {
        display: none;
        height: 16px;
        margin-right: 4px;
        &.minimizable {
          display: inline-block;
        }
      }
      select {
        width: 100px;
      }
    }

    .footer {
      display: block;
      border-top: 1px solid $border-color;
      padding: 16px 16px 0px 16px;
    }

    .hide {
      > .details {
        display: none;
      }
    }
    .show {
      padding-left: 16px;
      > .details {
        display: block;
        margin-top: 8px;
      }
    }
    .alert-details {
      display: block;
      .header {
        span {
          color: $editor-inline-error-color;
        }
      }
    }

    .show-extra-fields {
      display: block;
      margin-top: 16px;
    }

    .hide-extra-fields {
      display: none;
    }

    .show-more {
      text-align: center;

      .show-more-button {
        @extend %body4;
        height: 30px;
        width: 80px;
        background: none;
        border: none;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .checkbox {
      @extend %body3;

      input {
        margin-left: 0px;
      }

      label {
        vertical-align: text-bottom;
        line-height: 1.3;
        padding-left: 2px;
      }
    }

    .hint {
      @extend %body4;
      margin-top: 2px;
    }
  }

  .code-builder {
    padding-bottom: 16px;
  }
}
