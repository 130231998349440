@import 'app';

.editor-card-container-spacer {
  margin-top: 40px;
}

.editor-card-container {
  .error {
    color: $editor-body-required-color;
  }

  .color-error {
    margin-left: 16px;
  }

  .edit-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background-color: transparent;

    svg {
      margin-right: 4px;
    }

    .edit-text {
      display: none;
    }

    &:hover {
      cursor: pointer;
      color: $primary-color;

      .edit-text {
        display: inline;
      }
    }
  }

  .actions-container {
    display: flex;

    .action-buttons {
      display: flex;
      flex: 1;

      button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }

      button:not(:last-child) {
        margin-right: 8px;
      }
    }

    .input-field {
      width: 200px;
      margin-right: 8px;
      .highlight {
        border-color: $editor-body-required-color;
      }
    }
  }

  &.spacer {
    margin-top: 32px;
  }

  .card-text {
    @extend %body3;
    text-overflow: wrap;
    overflow: hidden;
    &.title {
      @extend %heading2;
      margin-left: 16px;
    }
    &.section-title {
      @extend %heading4;
      margin-left: 16px;
      text-transform: uppercase;
    }
    &.subtitle {
      @extend %heading3;
      margin-bottom: 8px;
    }
    &.description {
      @extend %body4;
      margin-top: 8px;
    }
    &.subdued {
      @extend %body4;
    }
  }

  .tappable {
    &.link {
      color: $action-color;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .action {
    float: right;
    cursor: pointer;
  }

  .padded {
    padding: 16px;
  }

  .mt-0 {
    margin-top: 0px !important;
  }

  .alt-card {
    margin-top: 16px;
    border-radius: $border-radius;
    border: 1px solid $complementary-color;
    padding: 16px;

    .item {
      margin-top: 16px;
    }
  }

  .card {
    margin-top: 16px;
    border-radius: $border-radius;

    &.shadow {
      box-shadow: 2px 2px 4px $border-color;
    }

    @import 'inputs';

    .details {
      margin-bottom: 8px;
      border: 1px solid transparent;

      &.highlight {
        cursor: pointer;
        border-radius: $border-radius;
        border: 1px solid $primary-color;
      }

      .header {
        border-radius: $border-radius $border-radius 0 0;
        border-bottom: none;
        margin-bottom: 0;

        &.minimized {
          border-bottom: 1px solid $border-color;
          border-radius: $border-radius;
        }
      }

      .body {
        border-radius: 0 0 $border-radius $border-radius;
      }
    }

    .header {
      @extend %heading3;
      background-color: $editor-heading-background-color;
      color: $editor-heading-text-color;
      padding: 8px;
      margin-bottom: 8px;

      .remove-button {
        float: right;
        cursor: pointer;

        img {
          height: 25px;
          width: 25px;
        }
      }

      .image-title {
        // TODO: Simplify improve this vertical align value hardcoded
        vertical-align: 7px;
        margin-right: 8px;
      }

      img {
        height: 30px;
        width: 30px;
        object-fit: contain;
      }
    }

    .padding {
      padding: 16px !important;
    }

    .h-padding {
      padding: 0px 16px !important;
    }

    .body {
      @extend %heading4;
      background-color: $editor-body-background-color;
      color: $editor-body-text-color;
      padding: 16px 0px;
      border-radius: 4px;
      border: 1px solid $border-color;
      transition-duration: 0.3s;

      &.minimized {
        transition-duration: 0.1s;
        padding: 0px;
        height: 0px;
        opacity: 0;
        visibility: hidden;
        border: none !important;

        .input-field {
          pointer-events: none;
        }
      }

      .fields-header {
        label {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: $heading-text-color;
        }
        margin-bottom: 8px;
      }

      .fields-row {
        display: flex;
        flex: 1;
        // flex-wrap: wrap;
        overflow: hidden;
        :not(:last-child) {
          margin-right: 8px;
        }
      }

      .fields {
        padding: 0px 16px !important;
      }

      .block-fields {
        -webkit-column-count: 2; /* Chrome, Safari, Opera */
        -moz-column-count: 2; /* Firefox */
        column-count: 2;
        -webkit-column-gap: 32px;
        -moz-column-gap: 32px;
        column-gap: 32px;
        -webkit-column-rule: 1px solid $border-color;
        -moz-column-rule: 1px solid $border-color;
        column-rule: 1px solid $border-color;
        padding: 0px 16px;

        .field {
          break-inside: avoid;
        }
        .react-select {
          break-inside: avoid;
        }

        /* Breakpoint multicolumn */
        @media screen and (max-width: 768px) {
          display: block !important;
          -webkit-column-count: 1; /* Chrome, Safari, Opera */
          -moz-column-count: 1; /* Firefox */
          column-count: 1;
        }
      }

      .image-fields {
        -webkit-column-count: 2; /* Chrome, Safari, Opera */
        -moz-column-count: 2; /* Firefox */
        column-count: 2;
        -webkit-column-gap: 32px;
        -moz-column-gap: 32px;
        column-gap: 32px;
        -webkit-column-rule: 1px solid $border-color;
        -moz-column-rule: 1px solid $border-color;
        column-rule: 1px solid $border-color;
        padding: 0px 16px;

        .field {
          break-inside: avoid;
        }
        .react-select {
          break-inside: avoid;
        }

        /* Breakpoint multicolumn */
        @media screen and (max-width: 768px) {
          display: block !important;
          -webkit-column-count: 1; /* Chrome, Safari, Opera */
          -moz-column-count: 1; /* Firefox */
          column-count: 1;
        }
      }

      &.alt {
        background-color: $editor-alt-body-background-color;
        color: $editor-alt-body-text-color;
      }

      .inline-form {
        background-color: $inline-form-body-background-color;
        border-radius: $border-radius;
        margin-top: 16px;
      }

      .grid {
        display: flex;
        flex-flow: row wrap;

        .field {
          width: 100px;
          height: 120px;
          padding: 16px;

          display: flex;
          flex-flow: row wrap;
          align-items: center;
          justify-content: center;

          .display {
            display: flex;
            justify-content: center;
            width: 100px;
            height: 100px;

            .bubble {
              @extend %heading2;
              height: 100px;
              width: 100px;
              border-radius: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $editor-body-bubble-background;
              border: 1px solid $border-color;

              &.add {
                border: none;
              }

              &.add img {
                height: 100%;
                width: 100%;
              }

              &.add:hover {
                border: none;
              }

              &:hover {
                border: 1px solid $editor-display-bubble-border;
              }
            }
          }

          .title {
            @extend %body3;
            margin-top: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .field {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        width: 100%;

        &.highlight {
          input,
          select,
          textarea {
            border-color: $editor-body-required-color;
          }
        }

        &.last {
          margin-bottom: 0px;
        }

        &.tappable:hover {
          cursor: pointer;
        }

        .input-field-title {
          font-size: 12px;
          color: $button-text-color;
          width: 100%;
          white-space: nowrap;

          &.padding {
            vertical-align: 43px;
          }
        }

        .file-picker-img-container {
          display: flex;
          flex: 1;
          align-items: center;
          justify-content: center;
          background-color: $background-color;
          border: 1px solid $border-color;
          border-radius: $border-radius;
          margin-bottom: 8px;
          padding: 20px 8px;
          overflow: hidden;
          min-height: 96px;
          max-height: 150px;

          .img-preview-container {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            min-height: 70px;

            svg {
              margin-bottom: 8px;
              width: 40px;
              height: 40px;
            }

            .button {
              @extend %heading4;
              color: $button-primary-text-color;
            }
          }
        }

        .file-picker-img-preview {
          object-fit: cover;
          cursor: zoom-in;
          height: auto;
          width: auto;
          max-width: 150px;
          max-height: 150px;
        }

        .file-picker-link {
          margin-bottom: 16px;
          a {
            font-size: 10px;
            color: $text-color;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }

        .file-picker-field {
          display: flex;
          align-items: center;
          margin-bottom: 4px;

          input {
            width: 100%;
          }

          .file-upload {
            padding-left: 8px;
            display: flex;

            .button {
              height: 23px;
              display: flex;
              align-items: center;
              svg {
                margin-right: 4px;
              }
            }
          }
        }

        .file-picker-field-embed {
          display: flex;
          flex-direction: column;
          align-items: end;

          .floating-error-container {
            width: 100%;

            input {
              width: 100%;
            }
          }

          .action-buttons {
            margin-top: 8px;
            margin-bottom: 16px;

            .button {
              @extend %heading3;
              margin-left: 8px;
              padding: 4px 8px;
            }
          }
        }

        .behaviour-field {
          .behavior {
            margin-bottom: 8px;
          }
        }

        .input-field {
          font-size: 12px;
          margin-top: 4px;
          width: 100%;

          .error {
            color: $editor-body-required-color;
          }

          .highlight {
            border-color: $editor-body-required-color;
          }

          textarea {
            resize: none;
          }

          .radio-title {
            vertical-align: 2px;
          }

          .button {
            border-radius: $input-border-radius;
          }

          .help-field {
            padding: 8px;
            margin-top: 4px;
            background-color: #ccc;
            color: #000;
            max-width: 500px;
          }

          &.button-field {
            .button {
              margin-right: 8px;
            }
          }

          .help-text {
            .title {
              font-weight: 600;
              margin-bottom: 4px;
            }
            .example {
              line-height: 2;
            }
            .code {
              padding: 4px;
              background-color: #ccc;
              color: #000;
            }
          }

          .tag-wrapper {
            padding: 8px;
            background-color: $alt-complementary-color;
            border-radius: $input-border-radius;
            color: $text-color;
            margin-right: 8px;
            button {
              padding-top: 2px;
              margin-left: 8px;
              margin-bottom: 8px;
              height: 20px;
              width: 20px;
              border: none;
              border-radius: $input-border-radius;
            }
          }

          .preview-field {
            display: flex;
            justify-content: space-between;
            align-items: center;
            select {
              width: 100%;
            }
            .bubble {
              @extend %body4;
              display: flex;
              justify-content: space-between;
              align-items: center;
              justify-content: center;
              margin-right: 16px;
              height: 30px;
              width: 30px;
              border-radius: 15px;
              box-shadow: 1px 1px 12px $editor-preview-bubble-shadow;
            }
          }

          .new-selector {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .select-container {
              flex: 1;
            }

            .button {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-left: 8px;
              height: 40px;
              &:disabled {
                background-color: $background-color;
                color: $editor-title-text-color;
                cursor: not-allowed;
              }
              svg {
                margin-right: 4px;
              }
            }
          }

          .padding-field {
            width: 100%;
            display: inline-block;

            .sample-container {
              display: flex;
              box-sizing: border-box;
              align-items: center;
              justify-content: center;
              width: 120px;
              height: 120px;
              /* gray-200 */
              background: $border-color;
              /* gray-300 */
              border: 1px dashed $button-shadow-color;
              border-radius: 2px;
              margin-bottom: 8px;
              transition: padding 0.4s ease 0.1s;
            }

            .box-w-padding {
              display: flex;
              flex: 1;
              align-self: stretch;
              /* white */
              background: #ffffff;
              /* gray-300 */
              border: 1px solid $button-shadow-color;
              border-radius: 2px;
              box-sizing: border-box;
            }

            .padding-field-container {
              display: inline-block;
              width: 25%;
              /* Breakpoint multicolumn */
              @media screen and (min-width: 768px) {
                width: 50%;
                margin-bottom: 16px;
              }
              .padding-field-cell {
                select {
                  margin-top: 4px;
                }
              }
            }

            /* Breakpoint multicolumn - Margin right just for the last field */
            @media screen and (max-width: 768px) {
              .padding-field-container:not(:last-of-type) {
                .padding-field-cell {
                  margin-right: 8px;
                }
              }
            }

            /* Breakpoint multicolumn - Margin right for the fields in pair index */
            @media screen and (min-width: 768px) {
              .padding-field-container:nth-of-type(2n) {
                .padding-field-cell {
                  margin-right: 8px;
                }
              }
            }

            .padding-field-title {
              color: $button-text-color;
            }
          }

          .file-upload {
            input[type='file'] {
              display: none;
            }
          }
        }
      }
    }
  }
}
