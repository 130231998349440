@import "styles";

@media (max-width: 768px) {
  body {
    .new-message-steps {
      .buttons {
        float: right;
        margin-right: 32px;
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }

    .top-menu {
      .left-container {
        margin-left: 16px;
  
        .nav {
          margin-left: 32px;
          .item {
            margin-right: 32px;
          }
        }
      }
      .right-container {
        right: 16px;
        .nav {
          .item {
            margin-left: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: 430px) {
  body {
    .top-menu {
      .left-container {
        .nav {
          display: none;
        }
      }
    }

    .new-message-steps {
      .buttons {
        .button {
          min-width: 100px;
        }
      }
    }

    .top-padded {
      margin-top: 16px;
    }

    .action-menu {
      a {
        text-decoration: none;
      }
      
      .page-nav {
        float: none;
      }

      .action-buttons {
        float: right;
        margin-top:32px;
        
        .action-button {
          vertical-align: 0px;
        }
      }
    }

    .cards {
      .item-card-container {
        width: 100%;

        margin: 0px;
        margin-bottom: 16px;
      }
    }
  
    // Builder
    .editor-card-container {
      .builder {
        .body {
          // TODO: Cannot change font size through extention, class already extended. Need to find a better way.
          font-size: 12px;
        }

        .component-builder {
          padding-left: 12px;
        }
        .component-builder .show {
          padding-left: 4px;
        }
      }
    }

    .item-list-container {
      .details {
        padding: 4px;
      }
      .action {
        button {
          width: 105px;
          &.spaced {
            margin-bottom: 8px;
          }
        }
      }
    }

    .live-preview {
      &.sticky {
        position: relative;
        top: 0px;

        .live-preview-card {
          padding: 0px;
          .live-preview-container {
            padding: 0px;
            .live-preview-frame {
              padding: 0px;
              width: 100%;
            }
          }
        }
      }
    }
    
    .list {
      .action {
        flex-direction: column;
        button {
          &.spacer {
            margin-left: 0px !important;
            margin-top: 8px;
          }
        }
      }
    }
  }
}