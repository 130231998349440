@import 'styles';

.toggle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  gap: 4px;
  border-radius: 4px;
  background-color: white;

  * {
    cursor: pointer;
  }
  button {
    border: none;
    background: none;
    cursor: pointer;
  }
}

.toggle-option {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background-color: white;
  padding: 0px 8px;
  gap: 4px;
  .icon {
    svg {
      fill: $editor-title-text-color;
    }
  }
  label {
    color: $editor-body-text-color;
    font-size: 14px;
    line-height: 20px;
  }
  span {
    width: 24px;
    height: 24px;
  }
  &.active {
    color: $button-text-color;
    background-color: $border-color;
    -webkit-transition: background-color 0.2s linear;
    -moz-transition: background-color 0.2s linear;
    -o-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
    border-radius: 4px;
    label {
      color: $heading-text-color;
      font-weight: 600;
    }
    .icon {
      svg {
        fill: $button-text-color;
      }
    }
  }
}
