@import './clear.css';
@import './flexboxgrid.min.css';

// Colors
$primary-color: #08272b;
$complementary-color: #ffffff;
$alt-complementary-color: #bdbdbd;
$background-color: #f8f9f9;
$secondary-color: #3f4e50;
$tertiary-color: #0a173e;
$bottom-action-bar-background-color: #f0f0f080;
$alt-button-text-color: #08272b;
$bottom-action-bar-button-primary-color: #08272b;
$bottom-action-bar-button-text-color: #ffffff;
$bottom-action-bar-button-primary-hover-color: #0e464d;
$bottom-action-bar-button-text-hover-color: #ebf0f1;
$border-color: #ebf0f1;
$button-background-color: #ffffff;
$button-shadow-color: #ebf0f1;
$button-text-color: #3f4e50;
$button-primary-color: #08272b;
$button-primary-border-color: #08272b;
$button-primary-text-color: #ffffff;
$button-primary-hover-color: #0e464d;
$button-primary-hover-text-color: #ebf0f1;
$button-primary-disabled-color: #cad6d8;
$button-primary-disabled-border-color: #cad6d8;
$button-primary-disabled-text-color: #ffffff;
$button-hover-background-color: #ebf0f1;
$button-delete-color: #e22828;
$button-subtle-hover-delete-border-color: #ffedf0;
$error-border-color: #ff9cae;
$error-text-color: #69002c;
$error-danger-color: #db234b;
$editor-display-bubble-border: #606060;
$heading-text-color: #202c2d;
$editor-heading-text-color: #484b60;
$editor-heading-background-color: #ffffff;
$editor-body-border-color: #ebf0f1;
$editor-body-text-color: #3f4e50;
$editor-title-text-color: #66787a;
$editor-body-background-color: #ffffff;
$editor-body-bubble-background: #ffffff;
$editor-body-required-color: #e22828;
$editor-alt-body-background-color: #ffffff;
$editor-alt-body-text-color: #3f4e50;
$editor-preview-bubble-shadow: #0000001a;
$editor-inline-error-color: #e22828;
$inline-form-body-background-color: #ffffff;
$input-background-color: #ffffff;
$input-border-color: #505050;
$input-focus-border-color: #006ff5;
$input-text-color: #505050;
$input-placeholder-text-color: #6d6d6d;
$text-color: #3f4e50;
$alt-text-color: #ffffff;
$action-text-color: #006ff5;
$action-color: #006ff5;
$message-preview-underlay: #ffffff;
$message-preview-border-color: #ebf0f1;
$message-preview-background-color: #ebf0f1;
$message-preview-over-background-color: #505050;
$help-background: #eeeeee;
$tooltip-background-color: #343446;

// Fonts
$font: -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica', 'Arial',
  sans-serif;

%heading {
  font-family: $font;
  font-weight: 700;
  font-size: 64px;
  line-height: 1.5;
  color: $heading-text-color;
}

%subheading {
  font-family: $font;
  font-weight: 400;
  font-size: 32px;
  line-height: 1.5;
  color: $heading-text-color;
}

%onboarding-heading {
  font-family: $font;
  font-weight: 700;
  font-size: 48px;
  line-height: 1.25;
  color: $heading-text-color;
}

%onboarding-subheading {
  font-family: $font;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: $heading-text-color;
}

%heading1 {
  font-family: $font;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.3;
  color: $heading-text-color;
}

%heading2 {
  font-family: $font;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: $heading-text-color;
}

%heading3 {
  font-family: $font;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  color: $heading-text-color;
}

%heading4 {
  font-family: $font;
  font-weight: 600;
  font-size: 11px;
  line-height: 1.1;
  color: $heading-text-color;
}

%body1 {
  font-family: $font;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.5;
}

%body2 {
  font-family: $font;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
}

%body3 {
  font-family: $font;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.5;
}

%body4 {
  font-family: $font;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
}

%body5 {
  font-family: $font;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5;
}

%preview-heading {
  font-family: $font;
  font-weight: 700;
  font-size: 24px;
  line-height: 1.2;
}

%preview-body {
  font-family: $font;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
}

%preview-button {
  font-family: $font;
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
}

%button {
  font-family: $font;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
}

// Other Styles

$border-radius: 8px;
$button-border-radius: 3px;
$onboarding-button-border-radius: 8px;
$input-border-radius: 4px;

body {
  background-color: $background-color;
  font-family: $font;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $button-primary-color;
}

.button {
  @extend %button;
  padding: 8px 12px;
  text-align: center;
  background-color: $button-background-color;
  border-radius: $button-border-radius;
  color: $button-text-color;
  outline: none;
  border: 1px solid $button-shadow-color;
  cursor: pointer;

  img {
    vertical-align: -3px;
  }

  &.alt {
    border: 1px solid $button-shadow-color;
    background: none;
    color: $alt-button-text-color;

    &:hover {
      background: none;
      color: $alt-button-text-color;
    }
  }

  &.primary {
    background-color: $button-primary-color;
    color: $button-primary-text-color;
    border: none;

    &:hover {
      background-color: $button-primary-hover-color;
      color: $button-primary-hover-text-color;
    }

    &.disabled {
      background-color: $button-primary-disabled-color;
      border-color: $button-primary-disabled-border-color;
      color: $button-primary-disabled-text-color;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.subtle {
    border: 1px solid transparent;
    background-color: transparent;
    box-shadow: none;
    filter: none;

    &:hover {
      background-color: transparent;
      border: 1px solid $border-color;
    }

    &.danger {
      color: $button-delete-color !important;

      &:hover {
        border: 1px solid $button-subtle-hover-delete-border-color;
      }
    }
  }

  &.delete-alt {
    border-color: $button-primary-color;
    color: $button-delete-color;

    &:hover {
      background-color: $button-hover-background-color;
    }
  }

  &.delete {
    border: 1px solid $button-shadow-color;
    color: $button-delete-color;

    &:hover {
      border: 1px solid $button-delete-color;
      background-color: $button-delete-color;
      color: $button-primary-text-color;
    }
  }

  &:hover {
    background-color: $button-hover-background-color;
    cursor: pointer;
  }

  &.small {
    min-width: 80px;
  }

  &.disabled {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.template-selector {
  overflow: hidden;
  .template-sections {
    height: 0px;
    opacity: 0;
    transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
    &.visible {
      height: auto;
      overflow-x: scroll;
      visibility: visible;
      opacity: 1;
    }
  }

  hr {
    height: 1px;
    background-color: $border-color;
    border: none;
  }

  .templates-section {
    padding-top: 8px;
    .template-section {
      padding-top: 24px;
    }
    .section-title {
      color: $editor-title-text-color;
      position: absolute;
      margin-top: -5px;
    }
  }
}

.extra-bottom-margin {
  margin-bottom: 100px;
}

.tappable {
  &:hover {
    cursor: pointer;
  }
}

.modal {
  position: fixed;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.modal-overlay {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  .modal-overlay-container {
    z-index: 1001;
    background-color: $background-color;
    border-radius: $border-radius;
    padding: 32px;
    width: 100%;
    max-width: 700px;
    position: absolute;
    border: none;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }
}

.tooltip {
  @extend %body3;
  background: $tooltip-background-color;
  color: $complementary-color;
  padding: 4px 12px;
  max-width: 200px;
  border-radius: 4px;
  display: none;
}

.tooltip[data-show] {
  display: block;
}

.arrow,
.arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.arrow {
  visibility: hidden;
}

.arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.tooltip[data-popper-placement^='top'] > .arrow {
  bottom: -4px;
}

.tooltip[data-popper-placement^='bottom'] > .arrow {
  top: -4px;
}

.tooltip[data-popper-placement^='left'] > .arrow {
  right: -4px;
}

.tooltip[data-popper-placement^='right'] > .arrow {
  left: -4px;
}
