@import 'styles';

// Text

.top-title-text {
  @extend %heading1;
  color: $text-color;

  &.capitalize {
    text-transform: capitalize;
  }
}

.action-card-text {
  &.title {
    @extend %heading1;
  }

  &.body {
    @extend %body3;
  }

  &.description {
    @extend %body4;
  }
}

.action-text {
  @extend %body3;
  color: $action-text-color;
}

.top-menu-link {
  @extend %body2;
  color: $alt-text-color;
  text-decoration: none;

  &.selected {
    @extend %heading2;
    color: $alt-text-color;
  }
}

// Structures

.visual-selector {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));

  .item {
    margin: 8px;
    border-radius: $border-radius;
    border: 1px solid $message-preview-border-color;
    overflow: hidden;

    .preview {
      background-color: $message-preview-background-color;
      overflow: hidden;
      height: 102px;
      padding: 8px;

      .container {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    .details {
      padding: 16px;

      .title {
        @extend %heading3;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &.selected {
      border: 1px solid $primary-color;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

  .dragging {
    .over {
      .item-card-container {
        .preview {
          background-color: $message-preview-background-color;
        }
      }
    }
  }

  .over {
    .item-card-container {
      .preview {
        background-color: $message-preview-over-background-color;
      }
    }
  }

  .item-card-container {
    @extend %body5;

    color: $editor-title-text-color;
    position: relative;
    border-radius: $border-radius;
    border: 1px solid $message-preview-border-color;
    margin: 16px;

    overflow: hidden;
    text-overflow: ellipsis;

    .overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
    }

    .preview {
      background-color: $message-preview-background-color;
      overflow: hidden;
      text-align: center;
      display: flex;
      align-items: center;
      height: 130px;
      padding: 8px;

      .container {
        width: 300px;

        .loading {
          position: absolute;
          transform: translateX(-50%) translateY(0%) scale(1);
          width: 60px;
          top: 60px;
          left: 50%;
        }

        .icon {
          @extend %heading1;
          font-size: 60px;
          position: absolute;
          transform: translateX(-50%) translateY(0%) scale(1);
          top: 25px;
          left: 50%;
        }

        .illustration {
          padding: 0px;
          margin: 0px;
          overflow: hidden;
        }

        .live-preview-frame {
          width: 414px;
          height: 270px;
          overflow: hidden;
          position: absolute;
          transform: translateX(-50%) translateY(-48%) scale(0.5);
        }
      }
    }

    &.selected {
      border: 2px solid $primary-color;
    }

    .body {
      height: 100%;

      .underlay {
        background-color: $message-preview-underlay;
        height: 100%;

        .details {
          padding: 16px;

          .title {
            @extend %heading3;
            color: $heading-text-color;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.list {
  display: flex;
  flex-direction: column;

  .section-title {
    @extend %body2;
    margin: 16px 8px;
  }

  .item-list-container {
    align-items: center;
    display: flex;
    justify-content: center;
    justify-content: space-between;

    &.notice {
      justify-content: center;

      .message {
        @extend %body3;
      }
    }

    border-radius: $border-radius;
    background-color: $complementary-color;
    padding: 16px;

    margin-bottom: 16px;

    overflow: hidden;
    text-overflow: ellipsis;

    @extend %body5;

    .spaced {
      margin-right: 8px;
    }

    .details {
      .title {
        @extend %heading2;

        &.capitalize {
          text-transform: capitalize;
        }
      }

      .status {
        @extend %body3;
        margin-top: 8px;
      }

      .topics {
        @extend %body3;

        ul {
          display: block;

          li {
            float: left;
            padding: 8px;
            background-color: $alt-complementary-color;
            color: $text-color;
            border-radius: $border-radius;
            text-align: center;
            margin-right: 8px;
            margin-top: 8px;
          }
        }
      }

      .subtitle {
        margin-top: 8px;
      }

      .stats {
        text-align: center;
        margin-bottom: 16px;

        .stat {
          @extend %body3;
        }
      }
    }

    .action {
      display: flex;
      justify-content: flex-end;

      button {
        min-width: 100px;

        &.spacer {
          margin-left: 8px;
        }
      }

      .title {
        @extend %body3;
        margin: 8px;
      }
    }
  }
}

.top-menu {
  background-color: $primary-color;
  width: 100%;
  height: 80px;
  position: relative;
  z-index: 100;

  .left-container {
    position: absolute;
    top: 50%;
    margin-left: 32px;
    transform: translate(0, -50%);

    .nav {
      margin-left: 96px;
      vertical-align: 20px;

      .item {
        cursor: pointer;
        margin-right: 56px;
      }
    }

    .logo {
      cursor: pointer;
    }
  }

  .right-container {
    position: absolute;
    top: 50%;
    right: 32px;
    transform: translate(0, -50%);

    .nav {
      .item {
        cursor: pointer;
        margin-left: 32px;
      }
    }
  }
}

.toolbar-sticky {
  display: flex;
  align-items: center;
  background-color: $background-color;
  height: 68px;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 999;
}

.action-menu {
  height: 36px;

  .page-nav {
    float: left;
    height: 36px;
    display: inline-flex;
    align-items: center;

    .title {
      cursor: pointer;
    }

    .back {
      cursor: pointer;
      margin-right: 4px;
      vertical-align: -12px;
      color: $editor-title-text-color;

      &:hover {
        color: $editor-body-text-color;
      }
    }
  }

  .edit-button {
    border: none;
    outline: none;
    background: none;
    display: inline-flex;
    align-items: center;
    height: 36px;
    color: $editor-title-text-color;

    .edit-text {
      @extend %body3;
      padding-left: 2px;
      display: none;
    }

    &:hover {
      svg {
        fill: $action-color;
      }

      cursor: pointer;
      color: $primary-color;

      .edit-text {
        display: inline;
      }
    }
  }

  .actions-container {
    display: flex;

    .input-field {
      .error {
        color: $editor-body-required-color;
      }

      .highlight {
        border-color: $editor-body-required-color;
      }

      input:focus {
        border-color: $primary-color;
      }
    }

    .action-buttons {
      display: flex;
      flex: 1;

      button:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }

    .input-field {
      width: 200px;
    }
  }

  .action-buttons {
    float: right;

    a {
      text-decoration: none;
    }

    .button {
      margin-left: 8px;
      -webkit-appearance: none;
    }

    button {
      &.button {
        line-height: 1.3;
      }
    }
  }
}

.stripped-embed-container {
  margin: -10px;
  margin-top: -26px;
}

.main-layout-container {
  border-radius: 3px;
  border: 1px solid $border-color;
}

.top-header {
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  background-color: #ffffff;
  border-bottom: 1px solid $border-color;

  .button-container {
    padding: 5px 0;
    height: 36px;
    display: flex;
  }

  .action-button {
    border: none;
    * {
      cursor: pointer;
    }
  }

  button {
    align-self: stretch;
    align-items: center;
    display: flex;
    background-color: white;
    border-radius: 3px;
    padding: 6px;

    label {
      color: $heading-text-color;
      font-weight: 600;
    }

    .icon {
      margin-right: 4px;
      justify-content: center;
      display: flex;
      .icon {
        svg {
          fill: $heading-text-color;
        }
      }
    }

    &.active {
      color: $heading-text-color;
      background-color: $border-color;
      -webkit-transition: background-color 0.2s linear;
      -moz-transition: background-color 0.2s linear;
      -o-transition: background-color 0.2s linear;
      transition: background-color 0.2s linear;
      border-radius: 4px;
      .icon {
        svg {
          fill: $heading-text-color;
        }
      }
      .no-fill {
        svg {
          fill: none;
        }
      }
    }
  }
}

h3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.main {
  padding-top: 40px;
  margin-left: 16px;
  margin-right: 16px;

  .main-body {
    margin-top: 24px;
  }
}

.main-body {
  margin-top: 20px;
}

.padding-t {
  padding: 16px 0px 0px 0px !important;
}

.h-padding {
  padding: 0px 16px !important;
}

// Custom CSS to override editor preview tooltip box-shadow
.tippy-preview {
  box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1) !important;
}
