@import "styles";

.new-message-steps {
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: $bottom-action-bar-background-color;

  &.embedded {
    pointer-events: none;
    position: absolute;
    top: 0px;
    bottom:auto;
    background:none;

    .buttons {
      pointer-events: all;
      margin-top: 8px;
      margin-right: 16px;
      margin-bottom: 0px;

      .button {
        min-width: 100px;
        line-height: 1.3;
      }
    }
  }

  .buttons {
    float: right;
    margin-right: 96px;
    margin-top: 24px;
    margin-bottom: 24px;
    .button {
      margin-left: 16px;
      min-width: 150px;
      border: none;

      &.primary {
        background-color: $bottom-action-bar-button-primary-color;
        color: $bottom-action-bar-button-text-color;
        &:hover {
          background-color: $bottom-action-bar-button-primary-hover-color;
          color: $bottom-action-bar-button-text-hover-color;
        }
      }
    }
  }
}