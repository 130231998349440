@import 'styles';

.tree-root {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
  padding: 8px 0px;
  min-height: 600px;
}

.tree-root ul li,
.tree-root ul li p {
  width: 100%;
  display: block;
}

.dragging-source {
  opacity: 0.65;
}

.placeholder-container {
  position: relative;
}

.list-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.list-item {
  display: flex;
  flex-direction: column;
  border-color: 1px dashed transparent;
  background-color: transparent;
  .drop-target {
    > :first-child {
      /* styles here */
      background-color: $border-color !important;
    }
    background-color: $background-color !important;
  }
}

.placeholder {
  font-size: 14px;
  font-family: $font;
  font-weight: 300;
}

.tree-node {
  align-items: center;
  cursor: pointer !important;
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  height: 32px;
  padding-inline-end: 8px;
  &.hover {
    background-color: $background-color;
  }
}

.expand-icon-wrapper {
  align-items: center;
  font-size: 0;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 20px;
  transition: transform linear 0.1s;
  transform: rotate(0deg);

  .chevron-container {
    padding: 12px;
  }
}

.expand-icon-wrapper.rotate {
  transform: rotate(-90deg);
}

.label-grid-item {
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;

  .icon {
    margin-right: 4px;
    display: flex;
  }

  p {
    font-family: $font;
    font-size: 14px;
  }
}
